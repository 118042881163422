const rezdy_modal = document.getElementById("rezdy-iframe-dialog")
if(rezdy_modal){
  const rezdy_modal_close = document.getElementById("close-rezdy-iframe-dialog")
  if(rezdy_modal_close){
    rezdy_modal_close.addEventListener("click", (e) => {
      e.preventDefault();
      document.getElementById("rezdy-iframe-dialog").close();
    });
  }
  document.querySelectorAll("a.rezdy-modal-open").forEach((el, i) => { 
    el.addEventListener("click", (e) => { 
      e.preventDefault();
      document.getElementById("rezdy-iframe-dialog").showModal(); 
    });
  });
}

function objectToUrlParams(obj) {
  const params = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key]) {
      params.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
    }
  }
  return params.join('&');
}

const survey_links = document.querySelectorAll("a[href^='https://survey.rallyready.com']");
const paid_attr = JSON.parse(localStorage.getItem("paid_attr"))
if(paid_attr !== null && typeof paid_attr == 'object' && paid_attr.hasOwnProperty("utm_source")){
    survey_links.forEach((el) => {
        el.href = el.href + "?" + objectToUrlParams(paid_attr);
    });
}